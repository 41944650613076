<script setup lang="ts">
import type { mastodon } from 'masto'

defineProps({
  "account": null,
  "hoverCard": { type: Boolean,  },
  "relationshipContext": null
})

cacheAccount(__props.account)
</script>

<template>
  <div flex justify-between hover:bg-active transition-100>
    <AccountInfo
      :account="account" hover p1 as="router-link"
      :hover-card="hoverCard"
      shrink
      overflow-hidden
      :to="getAccountRoute(account)"
    />
    <div h-full p1 shrink-0>
      <AccountFollowButton :account="account" :context="relationshipContext" />
    </div>
  </div>
</template>
